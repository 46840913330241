const routes = {
  login: (redirect = "") => `/user/login?redirect=${redirect}`,
  home: "/user",
  profile: "/user/profile",
  asset: "/user/asset",
  history: "/user/history",
  team: "/user/team",
  notification: "/user/notification",
  consumer: "/user/consumer",
  preset: "/user/preset",
  location: "/user/location",
  book: "/user/book",
  reminder: "/user/reminder",
  report: "/user/report",
  serviceTemplate: "/user/service-template",
  historyAssetCategory: "/user/history/list-category",
  historyInvoiceAssetCategory: "/user/history/list-invoice-category",
  historyAssetList: (itemId) =>
    `/user/history/detail-category?itemId=${itemId}`,
  historyInvoiceAssetList: (itemId) =>
    `/user/history/multiple-invoice?itemId=${itemId}`,
  presetDetails: (id, serviceId) =>
    `/user/preset/details?id=${id}&serviceTemplateDetailId=${serviceId}`,
  bookPage: (page, keyword) =>
    `/user/book?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  serviceTemplatePage: (page, keyword) =>
    `/user/servicetemplate?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  presetPage: (page, keyword) =>
    `/user/preset?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  consumerPage: (page, keyword) =>
    `/user/consumer?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  locationPage: (page, keyword) =>
    `/user/location?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  vendor: "/user/vendor",
  vendorPage: (page, keyword) =>
    `/user/vendor?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  consumerDetail: (id) => `/user/consumer/${id}`,
  addConsumer: "consumer/add-consumer",
  setting: "/user/setting",
  itemDetail: (id) => `/user/asset/${id}`,
  ItemAddService: (id, origin) =>
    `/user/asset/${id}/add-service${origin ? `?origin=${origin}` : ""}`,
  ItemAddInvoice: (id) => `/user/asset/${id}/add-invoice`,
  ItemAddServiceLogbook: (id) => `/user/asset/${id}/add-service/logbook`,
  ItemSelectPresetAddService: (id) =>
    `/user/asset/${id}/add-service/select-preset`,
  ItemEditService: (id, editServiceId) => `/user/asset/${id}/${editServiceId}`,
  itemServiceHistory: (id) => `/user/asset/${id}/history`,
  historyDetail: (id, origin) =>
    `/user/history/${id}${origin ? `?origin=${origin}` : ""}`,
  historyPage: (page, keyword) =>
    `/user/history?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  reminderPage: (page, keyword) =>
    `/user/reminder?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  assetPage: (page, keyword) =>
    `/user/asset?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  teamPage: (page, keyword) =>
    `/user/team?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  itemServicePending: (id) => `/user/asset/${id}/service-pending`,
  informationGainPage: (id, action) =>
    `/user/asset/${id}/gain?action=${action}`,
  addTeamPage: "/user/team/add-team",
  editTeamPage: (id) => `/user/team/edit-team?userId=${id}`,
  informationLocationPage: (id, action) =>
    `/user/asset/${id}/location?action=${action}`,
  informationConsumerPage: (id, action) =>
    `/user/asset/${id}/consumer?action=${action}`,
  servicePage: (id, action) => `/user/asset/${id}/service?action=${action}`,
  customerItemPage: (id, action) =>
    `/user/asset/${id}/customer?action=${action}`,
  stockServicePage: (id, serviceId, action, userServiceId) =>
    `/user/asset/${id}/stock-service?serviceId=${serviceId}&action=${action}${
      userServiceId ? `&userServiceId=${userServiceId}` : ""
    }`,
  assetListPage: "/user/asset/asset-list",
  prsetAssetListPage: "/user/preset/asset-list",
  assetTemplateListPage: (itemId, title) =>
    `/user/asset/asset-list/asset-template-list/${itemId}?title=${title}`,
  addAssetPage: (itemId, title, templateId) =>
    `/user/asset/asset-list/add-asset/${itemId}${
      templateId ? `/${templateId}` : ""
    }?title=${title}`,
  editAssetPage: (id) => `/user/asset/${id}/edit-asset`,
  importAssetPage: `/user/asset/asset-list/import-asset`,

  notificationSettingPage: "/user/setting/notification-setting",
  userAuthorityPage: "/user/setting/user-authority",
  tutorialMenuPage: "/user/setting/tutorial-menu",
  tutorialDetailPage: (tutorialId) =>
    `/user/setting/tutorial-menu/tutorial-detail/${tutorialId}`,
  consumerSettingPage: "/user/setting/consumer-setting",
  consumerDetailPage: (consumerId) =>
    `/user/setting/consumer-setting/consumer-detail/${consumerId}`,
  addConsumerPage: "/user/setting/consumer-setting/add-consumer",
  editConsumerPage: (consumerId) =>
    `/user/setting/consumer-setting/consumer-detail/${consumerId}/edit-consumer`,
  locationSettingPage: "/user/setting/location-setting",
  locationDetailPage: (type, locationName) =>
    `/user/setting/location-setting/location-detail/${type}/${locationName}`,
  changePinPage: "/user/setting/change-pin",
  reportDownloadPage: "/user/setting/report-download",
  invoiceSettingPage: "/user/setting/invoice-setting",
  assetTypePage: "/user/setting/report-download/asset-type",
  choosePeriodPage: (type, itemId) =>
    `/user/setting/report-download/choose-period?type=${type}${
      itemId ? `&itemId=${itemId}` : ""
    }`,
  changePhoneNumberPage: "/user/setting/change-phone-number",
  deleteAccountPage: "/user/setting/delete-account",
  confirmationDeleteAccount:
    "/user/setting/delete-account/confirmation-delete-account",
  addVendor: "/user/vendor/add-vendor",
  editVendor: (id) => `/user/vendor/${id}`,
  addBook: "/user/book/add-book",
  editBook: (id) => `/user/book/${id}`,
  historyVendorDetailList: (shopBookId, serviceId, transactionAt) =>
    `/user/history/vendor-detail?shopBookId=${shopBookId}&serviceId=${serviceId}&transactionAt=${transactionAt}`,
  reminderVendorDetail: (shopBookId, userServiceId, postponeAt, filter) =>
    `/user/reminder/detail?shopBookId=${shopBookId}&userServiceId=${userServiceId}&postponeAt=${postponeAt}&filter=${filter}`,
  serviceTemplateDetail: (id) =>
    `/user/preset/service-template-detail?id=${id}`,
  addPreset: (id) => `/user/preset/add-preset?serviceId=${id}`,
  multipleDetail: (id, origin) => `/user/history/multiple-detail?id=${id}`,
  editMultipleService: (id) => `/user/history/edit-multiple?id=${id}`,
};

export default routes;
