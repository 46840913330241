export default {
  meter_note: "Catat Meter",
  save_and_scan: "Simpan & Scan",
  type_meter_number: "Ketik angka meter",
  unpaid: "unpaid",
  paid: "paid",
  new_invoice: "Invoice Baru",
  add_invoice: "Add Invoice",
  add_item_invoice: "Add Item Invoice",
  billing_period: "Periode Tagihan",
  payment_date: "Tanggal Pembayaran",
  paid_evidence: "paid {{date}}",
  invoice_setting: "Pengaturan Invoice",
  invoice_number: "Nomor Invoice",
  invoice_code: "Kode Invoice",
  company_code: "Kode Perusahaan",
  separator: "Separator",
  next_number: "Nomor Selanjutnya",
  year: "Tahun",
  month: "Bulan",
  digit_count: "Jumlah Digit",
  number_format: "Format Nomor",
  add: "Add",
  maintenance: "Maintenance",
  electricity: "Listrik",
  water: "Air",
  sinking_fund: "Sinking Fund",
  reset_number: "Reset Nomor",
  monthly: "Setiap Bulan",
  weekly: "Setiap Pekan",
  yearly: "Setiap Tahun",
  basic_fee: "Tarif Dasar",
  minimum_usage: "Minimum Pakai",
  variable: "Variabel",
  semi_gross_area: "Luas Semi-Gross",
  nett_area: "Luas Nett",
};
